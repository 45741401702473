import { Alert, Button, Card, Col, Modal, Row, Table, Typography, Image, Spin } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { PlusOutlined, SendOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { apiCall, TOKEN_CHANNEL } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useNavigate } from 'react-router-dom';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;

function ListBlastWA() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sending, setSending] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    const titlePage = 'Blast WA';

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            key: 'no',
            render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Kategori',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'APPROVED') return <div style={{ color: 'green' }}>{record.status}</div>;
                else if (record.status === 'REJECTED') return <div style={{ color: 'red' }}>{record.status}</div>;
                else return <div style={{ color: 'yellow' }}>{record.status}</div>;
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button type="default" style={{ marginRight: 10 }} onClick={() => openModal('detail', record)}>
                        <UnorderedListOutlined />
                    </Button>
                    {record.status === 'APPROVED' && (
                        <Button
                            type="primary"
                            danger
                            style={{ marginRight: 10 }}
                            onClick={() => openModal('send', record)}
                            disabled={sending}
                        >
                            {sending ? <Spin size="small" /> : <SendOutlined />}
                        </Button>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);

    const fetchData = (page, pageSize) => {
        setLoading(true);
        const offset = (page - 1) * pageSize;
        const url = `/templateblast?limit=${pageSize}&offset=${offset}`;
    
        apiCall({
            method: 'GET',
            url: url,
            data: {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        })
            .then((res) => {
                if (res.status === 'success') {
                    setData(res.data);
                    setPagination({
                        ...pagination,
                        total: res.meta.pagination.total,
                    });
                } else {
                    modalError('Gagal memuat data template.');
                }
            })
            .catch((e) => {
                console.error(e.message);
                modalError('Gagal menyambung ke server.');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const searchData = (value) => {
        if (!value) {
            setData(dataAll);
        } else {
            const filteredData = dataAll.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
            setData(filteredData);
        }
    };

    const openModal = (type, record) => {
        if (type === 'detail') {
            var imageUrl = null;
            if (record.header !== null) {
                const headerExample = record.header.example;
                const regex = /"(https:\/\/[^"]+)"/;
                const match = headerExample.match(regex);
                imageUrl = match ? match[1] : null;
            }

            Modal.info({
                title: 'Detail Blast WA',
                content: (
                    <Card bordered={false}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Title level={5}>Nama</Title>
                                <Text>{record.name}</Text>
                            </Col>
                            <Col span={24}>
                                <Title level={5}>Gambar</Title>
                                {imageUrl ? <Image src={imageUrl} alt="Header Image" style={{ width: 100 }} /> : <Text>Tidak ada Gambar</Text>}
                            </Col>
                            <Col span={12}>
                                <Title level={5}>Kategori</Title>
                                <Text>{record.category}</Text>
                            </Col>
                            <Col span={12}>
                                <Title level={5}>Status</Title>
                                <Text style={{ color: record.status === 'APPROVED' ? 'green' : record.status === 'REJECTED' ? 'red' : 'yellow' }}>{record.status}</Text>
                            </Col>
                            <Col span={24}>
                                <Title level={5}>Text</Title>
                                <Text>{record.body}</Text>
                            </Col>
                        </Row>
                    </Card>
                ),
                onOk() { },
                width: 800,
            });
        } else if (type === 'send') {
            Modal.confirm({
                title: 'NOTICE',
                content: 'Anda ingin blast WA ini?',
                onOk: () => sendBlast(record),
            });
        }
    };

    const sendBlast = (record) => {
        setSending(true);
        const payload = {
            name:record.name,
            contact_list_id: '43c54b3a-935b-4382-b4b8-372253a5d8e0',
            message_template_id: record.id,
            channel_integration_id: TOKEN_CHANNEL,
            language: { code: 'id' },
            parameters: { body: [] },
        };
        apiCall({
            method: 'POST',
            url: '/broadcastWA',
            data: {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: payload,
            },
        })
            .then((res) => {
                if (res.status === 'success') {
                    setTextAlert('Blast WA berhasil dikirim!');
                    setIsAlert(true);
                } else {
                    modalError('Request sudah overload. coba beberapa jam kemudian')
                    console.error(res.message);
                    setSending(false);
                }
            })
            .catch((e) => {
                console.error(e.message);
                modalError('Gagal menyambung server')
                setSending(false);
            })
            .finally(() => {
                setSending(false);
            });
    };

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Blast WA</h3>
            <Alert
                message="Notice"
                description="Maks blast 2 kali dan akan memakan waktu lama dengan request 100 user/60-120dtk"
                type="warning"
                style={{ marginBottom: 20 }}
            />
            <Row>
                <Col span={8}>
                    <Search placeholder="Pencarian" allowClear enterButton="Search" size="medium" onSearch={searchData} />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => navigate('/formBlastWA')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah</b>
                    </Button>
                </Col>
            </Row>
            <Spin spinning={sending} size="large" tip="Sending...">
                <Table
                    size="middle"
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={pagination}
                    onChange={handleTableChange}
                />
            </Spin>
        </>
    );
}

export default ListBlastWA;
