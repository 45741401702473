import { HomeOutlined, BookOutlined, TeamOutlined, WhatsAppOutlined, SettingOutlined, ToolOutlined, TagsOutlined, ShopOutlined, WechatOutlined, SafetyOutlined, ShoppingCartOutlined, AppstoreOutlined, CopyrightOutlined, FormOutlined, NotificationOutlined, SafetyCertificateOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu, Button } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

function MenuSideBars() {
    const selector = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const [item, setItem] = useState();
    const [collapsed, setCollapsed] = useState(false);  // State untuk menyembunyikan atau menampilkan menu

    useEffect(() => {
        var user = JSON.parse(selector);
        var menu = [];
        menu.push(getItem('Dashboard', 'dashboard', <HomeOutlined />));
        if (user.RolesId === 1 || user.RolesId === 2) {
            menu.push(getItem('Chat', 'chat', <WechatOutlined />));
        }
        if (user.RolesId === 1) {
            menu.push(getItem('Order', 'order', <ShoppingCartOutlined />));
            menu.push(getItem('Shopify', 'shopify', <AppstoreOutlined />, [
                getItem('Data Kategori', 'category'),
                getItem('Data Produk', 'product'),
            ]));
            menu.push(getItem('Data Master', 'dataMaster', <AppstoreOutlined />, [
                getItem('Data Produk Populer', 'popularProduct'),
                getItem('Data Banner', 'banner'),
                getItem('Data Katalog', 'catalog'),
                getItem('Data Toko', 'store'),
                getItem('Data Promo Toko', 'storePromo'),
                getItem('Data Membership', 'membership'),
                getItem('Data Level Member', 'level'),
            ]));
            menu.push(getItem('E-Warranty', 'ewarranty', <SafetyCertificateOutlined />, [
                getItem('Data Garansi Produk', 'productWarranty'),
                getItem('Data Klaim Garansi', 'warrantyclaim'),
                getItem('Data Garansi Member', 'warrantymember'),
            ]));
            menu.push(getItem('Service Center', 'sc', <ToolOutlined />, [
                getItem('Data Service Center', 'serviceCenter'),
                getItem('Data Service Barang', 'productService'),
                getItem('Data Service Process', 'serviceProcess'),
                getItem('Data Sparepart', 'sparepart'),
            ]));
            menu.push(getItem('FlashSale', 'flashsale', <TagsOutlined />));
            menu.push(getItem('Notifikasi', 'notification', <NotificationOutlined />));
            menu.push(getItem('Blast WA', 'blastWA', <WhatsAppOutlined />));
            menu.push(getItem('Syarat Ketentuan', 'privacy', <SafetyOutlined />));
            menu.push(getItem('Data Karyawan', 'users', <TeamOutlined />));
            setItem(menu);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Sider
                width={200}
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                trigger={null}
            >
                <div style={{
                    padding: 15,
                    backgroundColor: "#FFF"
                }}>
                    <Button
                        type="default"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            borderRadius: 10,
                        }}
                    >
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </div>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['dashboard']}
                    style={{
                        height: '100%',
                        borderRight: 0,
                    }}
                    items={item}
                    onClick={({ key }) => navigate('/' + key)}
                />
            </Sider>
        </div>
    );
}

export default MenuSideBars;
