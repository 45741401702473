
import { Alert, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { useSelector } from 'react-redux';
import { numFormat } from '../../Utils/formatCurrency';

function ListMembership() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [load, setLoad] = useState(0);
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);
    const [sort, setSort] = useState({
        sortField: 'id',
        sortOrder: 'DESC',
    });

    // //Parameter
    const titlePage = 'User';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            render: (text, record, index) => (page.pagination.current - 1) * page.pagination.pageSize + index + 1,
        },
        {
            title: 'Nama',
            dataIndex: 'name',
            key: "name",
            sorter: true,
        },
        {
            title: 'Nomor Telp',
            dataIndex: 'mobile',
            key: "mobile",
            sorter: false,
            render: (text, record) => (record.mobile ? `0${record.mobile}` : null),
        },
        {
            title: 'Point',
            dataIndex: 'PointBalance',
            key: "PointBalance",
            sorter: true,
            render: (text, record) =>
                record.PointWallet ? `${numFormat(record.PointWallet.PointBalance)} Point` : `0 Point`,
        },
        {
            title: 'Role',
            dataIndex: 'Roles',
            key: "Roles",
            sorter: false,
            render: (text, record) => (record.MS_Role ? record.MS_Role.Roles : '-'),
        },
        // {
        //     title: 'Tanggal Registrasi',
        //     dataIndex: 'created_at',
        //     key: "created_at",
        //     sorter: false,
        //     render: (text, record) =>
        //         record.created_at ? moment(record.created_at).format('YYYY-MM-DD HH:mm:ss') : null,
        // },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        JSON.stringify(page),
        isAlert,
        load,
        // page.pagination.current, page.pagination.pageSize, sort.sortField, sort.sortOrder
    ]);

    const getApi = () => {
        setLoading(true);
        const { current, pageSize } = page.pagination;
        const { sortField, sortOrder } = sort;
        const url = `/user?role=3&search=${search}&page=${current}&limit=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`;

        const dataReq = {
            method: "GET",
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var item = res.data.list;
                setData(item);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        // total: res.data.count
                        total: res.data.pagination.total,
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }


    // Handle table change
    const handleTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        const sortField = sorter.field || 'id';
        const sortOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC';

        setPage((prev) => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                current,
                pageSize,
            },
        }));

        setSort({ sortField, sortOrder });
        setLoad(load + 1);
    };

    const searchData = (value) => {
        setSearch(value);
        setLoad(load + 1);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Membership</h3>
            <Search
                placeholder="Pencarian"
                allowClear
                enterButton="Search"
                size="medium"
                onSearch={searchData}
                style={{ marginBottom: 10, width: 300 }}
            />
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>
    );
}

export default ListMembership;