import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Spin,
    Upload
} from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'react-quill/dist/quill.snow.css';

function FormBlastWA() {
    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [uploadedUrl, setUploadedUrl] = useState(null);
    const [saving, setSaving] = useState(false);

    const uploadImage = async (info) => {
        setImageLoading(true);
        const formData = new FormData();
        formData.append('file', info.file);

        try {
            const response = await apiCall({
                method: 'POST',
                url: '/templateblast/upload',
                data: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + token
                    },
                    data: formData
                }
            });
            if (response.status === "success") {
                setUploadedUrl(response.data.h);
                setImageUrl(URL.createObjectURL(info.file));
            } else {
                modalError("Gagal mengupload gambar");
            }
        } catch (error) {
            modalError("Terjadi kesalahan saat mengupload gambar");
        } finally {
            setImageLoading(false);
        }
    };

    const save = async (values) => {
        setSaving(true);
        const payload = {
            name: values.name,
            category: "MARKETING",
            attributes: [
                {
                    components: [],
                    language: "id"
                }
            ]
        };

        if (uploadedUrl) {
            payload.attributes[0].components.push({
                type: "HEADER",
                format: "IMAGE",
                example: {
                    header_handle: [uploadedUrl]
                }
            });
        }

        if (values.body) {
            payload.attributes[0].components.push({
                type: "BODY",
                text: values.body
            });
        }

        try {
            if (location.state != null) {
                const { id } = location.state.item;
                await Api('PUT', '/templateblast/' + id, payload);
            } else {
                await Api('POST', '/templateblast', payload);
            }
            navigate(-1);
        } catch (error) {
            console.error(error);
        } finally {
            setSaving(false);
        }
    };

    const Api = (method, url, values) => {
        const dataReq = {
            method: method,
            url: url,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                data: values
            }
        };
        return apiCall(dataReq);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        if (location.state != null) {
            const item = location.state.item;
            form.setFieldsValue({
                name: item.name,
                body: item.body,
            });

            if (item.image !== undefined) {
                setImageUrl(item.image.src);
            }
        }
    }, [location.state, form]);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <Button 
                    type="primary" 
                    onClick={form.submit} 
                    size='middle' 
                    disabled={saving}
                >
                    {saving ? <Spin size="small" /> : <b style={{ marginLeft: 5 }}>Simpan</b>}
                </Button>
            </div>
            <Form form={form} name="form_store" layout="vertical" onFinish={save}>
                <Row>
                    <Col span={16}>
                        <Form.Item
                            name="name"
                            label="Nama"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nama tidak boleh kosong!'
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="body"
                            label="Text"
                            rules={[
                                {
                                    required: true,
                                    message: 'Text tidak boleh kosong!'
                                },
                            ]}
                        >
                            <Input.TextArea placeholder='tulis text anda' style={{ height: 300 }} />
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ textAlign: 'center' }}>
                        <p>Upload Gambar</p>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            onChange={uploadImage}
                            beforeUpload={() => false}
                        >
                            {imageUrl ? (
                                imageLoading ?
                                    <Spin />
                                    :
                                    <img
                                        src={imageUrl}
                                        alt="attachment"
                                        style={{ height: 200, width: 200 }}
                                    />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default FormBlastWA;
