
import { Alert, Button, Col, Modal, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { useSelector } from 'react-redux';
// import { QRCodeCanvas } from "qrcode.react";


function AUWhatsapp() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [loading, setLoading] = useState(false);
    const [QRCode, setQRCode] = useState(null);

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getApi = () => {
        const dataReq = {
            method: 'GET',
            url: '/auth/whatsapp',
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setQRCode(res.data);
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            console.log(e)
            setLoading(false);
        })
    }

    return (
        <>
            <div style={{ alignItems: "center", textAlign: "center" }}>
                {
                    QRCode !== null ?
                        <>
                            <h2 style={{ marginBottom: 10 }}>QR Code Whatsapp Automation</h2>
                            {/* <QRCodeCanvas
                                value={QRCode}
                                level={"H"}
                                style={{ width: 300, height: 300 }}
                            /> */}
                        </>
                        :
                        <Spin tip="Loading.." size="small" ><div style={{ padding: 50 }} /></Spin>
                }
            </div>
        </>
    );
}

export default AUWhatsapp;