
import { Alert, Button, DatePicker, Input, Modal, Row, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { apiCall } from '../../API/RestApi';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';

const { RangePicker } = DatePicker;

function ListOrder() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [receipt, setReceipt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataModal, setDataModal] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [searchDate, setSearchDate] = useState(null);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    // //Parameter
    const titlePage = 'Order';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Order',
            dataIndex: 'InvoiceNo',
            key: "InvoiceNo",
            sorter: false,
            render: (text, record) => {
                return (<b>{record.InvoiceNo}</b>);
            }
        },
        {
            title: 'Tanggal',
            dataIndex: 'CreatedAt',
            key: "CreatedAt",
            sorter: false,
            render: (text, record) => {
                return (<p>{moment(record.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
            }
        },
        {
            title: 'Customer',
            dataIndex: 'name',
            key: "name",
            sorter: false,
            render: (text, record) => {
                return (<p>{record.name}</p>);
            }
        },
        {
            title: 'Total',
            dataIndex: 'Total',
            key: "Total",
            sorter: false,
            render: (text, record) => {
                return (<p>{currencyFormat(record.Total)}</p>);
            }
        },
        {
            title: 'Status Pembayaran',
            dataIndex: 'StatusPayment',
            key: "StatusPayment",
            sorter: false,
            render: (text, record) => {
                if (record.StatusPayment === 'success')
                    return (<Tag color='green'>{record.StatusPayment}</Tag>);
                else
                    return (<Tag color='red'>{record.StatusPayment}</Tag>);
            }
        },
        {
            title: 'Status Pesanan',
            dataIndex: 'BuyStatus',
            key: "BuyStatus",
            sorter: false,
            render: (text, record) => {
                var statusId = record.TR_Payments[record.TR_Payments.length - 1].StatusId;
                var color = '';
                switch (parseInt(statusId)) {
                    case 1:
                        color = 'cyan';
                        break;
                    case 2:
                        color = 'blue';
                        break;
                    case 3:
                        color = 'orange';
                        break;
                    case 4:
                        color = 'orange';
                        break;
                    case 5:
                        color = 'gold';
                        break;
                    case 6:
                        color = 'yellow';
                        break;
                    case 7:
                        color = 'green';
                        break;
                    case 8:
                        color = 'red';
                        break;
                    case 9:
                        color = 'red';
                        break;
                    case 10:
                        color = 'red';
                        break;
                }
                return (<Tag color={color}>{record.TR_Payments[record.TR_Payments.length - 1].MS_BuyingStatus.BuyStatus}</Tag>);
            }
        },
        {
            title: 'Pengiriman',
            dataIndex: 'CourierCode',
            key: "CourierCode",
            sorter: false,
            render: (text, record) => {
                return (<b>{record.CourierCode}</b>);
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Row>
                            <Button type="primary" ghost style={{ marginRight: 10, marginBottom: 10 }} onClick={() => navigate('/orderdetail', { state: { item: record } })} >
                                <b style={{ marginLeft: 5 }}>Detail</b>
                            </Button>
                            {
                                parseInt(record.StatusOrder) !== 9 ?
                                    <Row>
                                        {
                                            parseInt(record.StatusOrder) === 2 ?
                                                <Button style={{ border: "1px solid #3CB371", marginRight: 10, marginBottom: 10 }} onClick={() => openModal(record, 'approve')}>
                                                    <b style={{ marginLeft: 5, color: "#3CB371" }}>Approve</b>
                                                </Button>
                                                :
                                                parseInt(record.StatusOrder) === 3 ?
                                                    <div>
                                                        <Button style={{ border: "1px solid #5867dd", marginRight: 10, marginBottom: 10 }} onClick={() => openModal(record, 'receipt')}>
                                                            <b style={{ marginLeft: 5, color: "#5867dd" }}>Input No. Resi</b>
                                                        </Button>
                                                        <Button style={{ border: "1px solid #5867dd", marginRight: 10, marginBottom: 10 }} onClick={() => openModal(record, 'booking')}>
                                                            <b style={{ marginLeft: 5, color: "#5867dd" }}>Booking</b>
                                                        </Button>
                                                    </div>
                                                    :
                                                    null
                                        }
                                        {
                                            parseInt(record.StatusOrder) < 4 &&
                                            <Button danger onClick={() => openModal(record, 'reject')}>
                                                <b style={{ marginLeft: 5 }}>Tolak Pesanan</b>
                                            </Button>
                                        }
                                    </Row>

                                    :
                                    <Button type='inline' style={{ color: "red" }}>Tidak Ada Rekening</Button>
                            }
                        </Row>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        var dates = '';
        if (searchDate !== null) {
            if (searchDate[0] !== '' && searchDate[1] !== '')
                dates = '&startDate=' + searchDate[0] + '&endDate=' + searchDate[1];
        }
        getApi('GET', '/order?page=' + page.pagination.current + dates, null, 'order');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert, searchDate]);

    const getApi = (method, url, data, type) => {
        setLoading(true);
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'order':
                        var item = res.data.rows;
                        setData(item);
                        setPage({
                            ...page,
                            pagination: {
                                ...page.pagination,
                                total: res.data.count
                            }
                        });
                        setLoading(false);
                        break;
                    case 'approve':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        setLoading(false);
                        setIsModal(false);
                        break;
                    case 'booking':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        setLoading(false);
                        setIsModal(false);
                        break;
                    case 'reject':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        setLoading(false);
                        setIsModal(false);
                        break;
                    case 'receipt':
                        setPage({
                            pagination: {
                                current: 1,
                                pageSize: 10,
                            },
                        });
                        setLoading(false);
                        setIsModal(false);
                        break;
                }
            } else {
                setLoading(false);
                setData([]);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const openModal = (item, type) => {
        setDataModal({ data: item, type: type });
        setIsModal(true);
    }

    const closeModal = () => {
        setDataModal(null);
        setIsModal(false);
    }

    const send = () => {
        var data_order = {
            "OrderId": dataModal.data.OrderId,
            "Resi":receipt
        }
        if (dataModal.type === 'approve') {
            getApi('POST', '/orderapprove', data_order, 'approve');
        } else if (dataModal.type === 'booking') {
            getApi('POST', '/orderbooking', data_order, 'booking');
        } else if (dataModal.type === 'receipt') {
            getApi('POST', '/orderreceipt', data_order, 'receipt');
        } else {
            getApi('POST', '/orderreject', data_order, 'reject');
        }
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Order</h3>
            <Row style={{ marginBottom: 10 }}>
                <RangePicker
                    onChange={(e, ev) => setSearchDate(ev)}
                    style={{ marginRight: 10 }}
                />
            </Row>
            <Table
                size="small"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Order" open={isModal} onOk={() => send()} onCancel={() => closeModal()} >
                {
                    dataModal !== null &&
                    <p>
                        {
                            dataModal.type === 'approve' ?
                                'Setujui orderan ini ?' :
                                dataModal.type === 'booking' ?
                                    'Booking Orderan ini' :
                                    dataModal.type === 'receipt' ?
                                        'Input Nomer Resi' :
                                        'Tolak Orderan ini ?'
                        }
                    </p>
                }
                {
                    dataModal !== null &&
                    dataModal.type === 'receipt' &&
                    <Input
                        placeholder='Input No. Resi'
                        onChange={(e) => setReceipt(e.target.value)}
                    />
                }
            </Modal>
        </>
    );
}

export default ListOrder;