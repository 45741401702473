
import { Alert, Button, Col, Form, Input, Modal, Radio, Row, Table, Tag } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import Search from 'antd/es/input/Search';
import { modalError } from '../../Component/Modal/ModalLayout';
import { useSelector } from 'react-redux';
import moment from 'moment';


function ListServiceCenter() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [dataModal, setdataModal] = useState(null);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    const [load, setLoad] = useState(0);
    const [form] = Form.useForm();
    // //Parameter
    const titlePage = 'Service Center';

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Service Center',
            dataIndex: 'ServiceCenter',
            key: "ServiceCenter",
            sorter: false,
        },
        {
            title: 'Deskripsi',
            dataIndex: 'Desc',
            key: "Desc",
            sorter: false,
        },
        {
            title: 'Status',
            dataIndex: 'IsActive',
            key: "IsActive",
            sorter: false,
            render: (text, record) => {
                if (record.IsActive === true)
                    return <Tag color="success">Aktif</Tag>;
                else
                    return <Tag color="error">Tidak Aktif</Tag>;
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'CreatedAt',
            key: "CreatedAt",
            sorter: false,
            render: (text, record) => {
                if (record.CreatedAt !== null)
                    return (<p>{moment(record.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return null;
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'UpdatedAt',
            key: "UpdatedAt",
            sorter: false,
            render: (text, record) => {
                if (record.UpdatedAt !== null)
                    return (<p>{moment(record.UpdatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return null;
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => openModal('edit', record)}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(page), isAlert,load]);

    const getApi = () => {
        setLoading(true);
        const dataReq = {
            method: "GET",
            url: '/servicecenter?search=' + search + '&page=' + page.pagination.current,
            data: {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                var item = res.data.rows;
                setData(item);
                setPage({
                    ...page,
                    pagination: {
                        ...page.pagination,
                        total: res.data.count
                    }
                });
                setLoading(false);
            } else {
                setLoading(false);
                console.log(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const postApi = (method, values) => {
        setLoading(true);
        setIsAlert(false);

        var url = '/servicecenter';
        if (method === 'DELETE') {
            url = '/servicecenter/' + values.ServiceCenterId;
        }
        const dataReq = {
            method: method,
            url: url,
            data: {
                data: values,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setIsAlert(true);
                setTextAlert(res.message);
                closeModal();
            } else {
                setLoading(false);
                modalError(res.message);
            }
        }).catch((e) => {
            setLoading(false);
            console.log(e.message);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => postApi('DELETE', v),
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setPage({
            pagination,
            filters,
            ...sorter,
        });
        if (pagination.pageSize !== page.pagination?.pageSize) {
            setData([]);
        }
    };

    const searchData = (value) => {
        setSearch(value);
        setLoad(load+1);
        setPage({
            pagination: {
                current: 1,
                pageSize: 10,
            },
        });
    }

    const closeModal = () => {
        setdataModal(null);
        setIsModal(false);
        form.resetFields();
    }

    const onFinish = (ev) => {
        const form = new FormData();
        if (dataModal != null) {
            ev.ServiceCenterId = dataModal.ServiceCenterId;
            for (const name in ev) {
                form.append(name, ev[name]);
            }
            postApi('PUT', form);
        } else {
            for (const name in ev) {
                form.append(name, ev[name]);
            }
            postApi('POST', form);
        }
    }


    const openModal = (type, data) => {
        if (type === 'edit') {
            setdataModal(data)
            form.setFieldsValue({
                ServiceCenter: data.ServiceCenter,
                Desc: data.Desc,
                IsActive: data.IsActive,
            })
            setIsModal(true);
        } else {
            setIsModal(true);
        }
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Service Center</h3>
            <Row>
                <Col span={8}>
                    <Search
                        placeholder="Pencarian"
                        allowClear
                        enterButton="Search"
                        size="medium"
                        onSearch={searchData}
                    />
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => { openModal('add', null); }}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Service Center</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                pagination={page.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
            <Modal title="Form Service Center" open={isModal} onOk={form.submit} onCancel={() => closeModal()} okText="Simpan" >
                <Form form={form} name="form" layout="vertical" onFinish={onFinish}>

                    <Form.Item
                        name="ServiceCenter"
                        label="Service Center"
                        rules={[
                            {
                                required: true,
                                message: 'Service Center kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="Desc"
                        label="Deskripsi"
                        rules={[
                            {
                                required: true,
                                message: 'Deskripsi kosong!'
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="IsActive"
                        label="Status"
                        rules={[
                            {
                                required: true,
                                message: 'Status kosong!'
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Radio value={true} defaultChecked={true} >Aktif</Radio>
                            <Radio value={false}>Tidak Aktif</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}

export default ListServiceCenter;