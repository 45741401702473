import { UserOutlined, NotificationOutlined, RightOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, Image, List, Popover, Row } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_TOKEN, UPDATE_USER } from '../../../../Redux/actions/userActions';

function Headers() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const s = useSelector((state) => state.user.user);
    const user = JSON.parse(s);

    const dataMenu = [
        {
            title: 'Transaksi dari Kasir',
            desc: ' Ada transaksi baru dari membership. Silahkan cek verifikasi membership'
        },
    ];
    //parameter
    // const user = JSON.parse(getUser("user"));

    //Menu Profile
    const text = <Button type="link" onClick={() => profile()}><span>Profile</span></Button>;
    const content = (
        <div>
            <Button type="link" danger onClick={() => logout()}>
                Log Out
            </Button>
        </div>
    );

    const contentNotif = (
        <div>
            <List
                size="small"
                dataSource={dataMenu}
                renderItem={item =>
                    <List.Item onClick={() => {
                        navigate('/verifyPoint');
                    }}>
                        <Row>
                            <Col>
                                <NotificationOutlined style={{ marginRight: 10 }} />
                            </Col>
                            <Col>
                                <b style={{ fontSize: 12 }}>{item.title}</b><br />
                                <div style={{ fontSize: 12 }}>{item.desc}</div>
                            </Col>
                            <Col style={{ alignSelf: "center" }}>
                                <RightOutlined style={{ marginLeft: 10 }} />
                            </Col>
                        </Row>
                    </List.Item>
                }
            />
        </div>
    );

    //Function
    const logout = () => {
        dispatch(UPDATE_TOKEN());
        dispatch(UPDATE_USER());
        navigate('/login');
    }

    const profile = () => {
        navigate('/profile');
    }


    return (
        <Header className="header">
            <Row>
                <Col span={16}>
                    <Image
                        preview={false}
                        width={50}
                        src="assets/logo.png"
                    />
                </Col>
                <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {/* <Popover placement="bottom" content={contentNotif} trigger="click" >
                        <Badge count={12} size="small">
                        <NotificationOutlined style={{ color: "#FFF" }} />
                        </Badge>
                    </Popover> */}
                    <Button type="link" 
                    style={{
                        color: "#FFF",
                    }} 
                    
                    danger onClick={() => profile()}>{user.name}</Button>
                    <Popover placement="bottom" title={text} content={content} trigger="click">
                        <Avatar icon={<UserOutlined />} />
                    </Popover>
                </Col>
            </Row>
        </Header>
    );
}

export default Headers;