
import { Alert, Button, Col, Modal, Row, Table } from 'antd';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { apiCall } from '../../API/RestApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { currencyFormat } from '../../Utils/formatCurrency';


function ListFlashSale() {
    const token = useSelector((state) => state.user.token);
    //LIBRARY
    const navigate = useNavigate();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [load, setLoad] = useState(0);
    const [page, setPage] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [count, setCount] = useState(250);
    const [pageInfo, setPageInfo] = useState('');
    const [isAlert, setIsAlert] = useState(false);
    const [textAlert, setTextAlert] = useState(false);

    const [product, setProduct] = useState([]);
    // //Parameter
    const titlePage = 'Flash Sale Set Periode';
    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            sorter: false,
            key: "no",
            render: (id, record, index) => { ++index; return (page.pagination.current - 1) * 10 + index },
        },
        {
            title: 'Nama Flash Sale',
            dataIndex: 'Title',
            key: "Title",
            sorter: false,
        },
        {
            title: 'Total Produk Flash Sale',
            dataIndex: 'FlashSales',
            key: "FlashSales",
            sorter: false,
            render: (text, record) => {
                return (<p>{record.FlashSales.length} Produk</p>);
            }
        },
        {
            title: 'Tanggal Buat',
            dataIndex: 'CreatedAt',
            key: "CreatedAt",
            sorter: false,
            render: (text, record) => {
                if (record.CreatedAt !== null)
                    return (<p>{moment(record.CreatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Tanggal Ubah',
            dataIndex: 'UpdatedAt',
            key: "UpdatedAt",
            sorter: false,
            render: (text, record) => {
                if (record.UpdatedAt !== null)
                    return (<p>{moment(record.UpdatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>);
                else
                    return '';
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <>
                        <Button type="primary" style={{ marginRight: 10 }} onClick={() => navigate('/formFlashsale', { state: { item: record } })}>
                            <EditOutlined />
                            <b style={{ marginLeft: 5 }}>Ubah</b>
                        </Button>
                        <Button type="primary" danger onClick={() => modalDeleteData(record)}>
                            <DeleteOutlined />
                            <b style={{ marginLeft: 5 }}>Hapus</b>
                        </Button>
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        getApi('GET', '/flashsale', 'all');
        getApi('GET', '/product?limit=250', 'product');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load]);


    const getApi = (method, url, type, data = null) => {
        setLoading(true);
        var query = ''
        if (pageInfo !== '' && type == 'product') {
            query = '&page_info=' + pageInfo
        }
        const dataReq = {
            method: method,
            url: url + query,
            data: {
                data: data,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        };
        apiCall(dataReq).then((res) => {
            if (res.status === 200) {
                switch (type) {
                    case 'all':
                        var item = res.data.rows;
                        setData(item);
                        setLoading(false);
                        break;
                    case 'product':
                        var item = res.data.products;
                        setCount(count + 250);
                        var row = [];
                        for (let i = 0; i < item.length; i++) {
                            row.push({
                                value: JSON.stringify(item[i]),
                                label: item[i].title,
                            });
                        }
                        let setDatas = [].concat(product, row);
                        setProduct(setDatas);
                        if (res.count >= count) {
                            setPageInfo(res.page_info);
                        } else {
                            setLoading(false);
                        }
                        break;
                    case 'del':
                        setLoad(load + 1);
                        setLoading(false);
                        break;
                }
            } else {
                setLoading(false);
            }
        }).catch((e) => {
            setLoading(false);
        })
    }

    const modalDeleteData = (v) => {
        Modal.confirm({
            title: 'NOTICE',
            content: 'Anda ingin menghapus data ini?',
            onOk: () => getApi('DELETE', '/flashsale/' + v.DataFlashSaleId, 'del'),
        });
    }

    return (
        <>
            <Helmet>
                <title>{titlePage}</title>
            </Helmet>
            {isAlert && <Alert message={textAlert} type="success" banner closable afterClose={() => setIsAlert(false)} />}
            <h3>Daftar Flash Sale</h3>
            <Row>
                <Col span={8}>
                </Col>
                <Col span={16} style={{ display: 'flex', justifyContent: 'flex-end' }} >
                    <Button style={{ marginBottom: 10 }} type="primary" onClick={() => navigate('/formFlashsale')}>
                        <PlusOutlined />
                        <b style={{ marginLeft: 5 }}>Tambah Flash Sale</b>
                    </Button>
                </Col>
            </Row>
            <Table
                size="middle"
                columns={columns}
                dataSource={data}
                loading={loading}
            />
        </>
    );
}

export default ListFlashSale;