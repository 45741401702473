import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedLayout from "../Component/Main/ProtectedLayout";
import Login from '../Views/Auth/Login';
import Dashboard from "../Views/Dashboard/Dashboard";
import NotFound from "../Views/NotFound/NotFound";
import ListStore from "../Views/Store/ListStore";
import FormStore from "../Views/Store/FormStore";
import ListBanner from "../Views/Banner/ListBanner";
import ListUsers from "../Views/Users/ListUsers";
import FormUsers from "../Views/Users/FormUsers";
import ListMembership from "../Views/Membership/ListMembership";
import ListStorePromo from "../Views/StorePromo/ListStorePromo";
import FormStorePromo from "../Views/StorePromo/FormStorePromo";
import ListCatalog from "../Views/Catalog/ListCatalog";
import ListServiceCenter from "../Views/ServiceCenter/ListServiceCenter";
import ListProductWarranty from "../Views/ProductWarranty/ListProductWarranty";
import Privacy from "../Views/Privacy/Privacy";
import ListNotification from "../Views/Notification/ListNotification";
import LiveChat from "../Views/Chat/LiveChat";
import ListOrder from "../Views/Order/ListOrder";
import DetailOrder from "../Views/Order/DetailOrder";
import ListCategory from "../Views/Category/ListCategory";
import FormCategory from "../Views/Category/FormCategory";
import ListProduct from "../Views/Product/ListProduct";
import ListFlashSaleBanner from "../Views/FlashSaleBanner/ListFlashSaleBanner";
import ListFlashSaleTime from "../Views/FlashSaleTime/ListFlashTime";
import ListFlashSaleProduct from "../Views/FlashSaleProduct/ListFlashSaleProduct";
import FormFlashSaleProduct from "../Views/FlashSaleProduct/FormFlashSaleProduct";
import ListFlashSale from "../Views/FlashSale/ListFlashSale";
import FormFlashSale from "../Views/FlashSale/FormFlashSale";
import ListPopularProduct from "../Views/PopularProduct/ListPopularProduct";
import ListLevel from "../Views/Level/ListLevel";
import ListWarrantyClaim from "../Views/WarrantyClaim/ListWarrantyClaim";
import FormWarrantyClaim from "../Views/WarrantyClaim/FormWarrantyClaim";
import DetailWarrantyClaim from "../Views/WarrantyClaim/DetailWarrantyClaim";
import ListWarrantyMember from "../Views/WarrantyMember/ListWarrantyMember";
import AUWhatsapp from "../Views/Automation/AUWhatsapp";
import ProductService from "../Views/ProductService/ProductService";
import Sparepart from "../Views/Sparepart/Sparepart";
import ServiceProcess from "../Views/ServiceProcess/ServiceProcess";
import DetailService from "../Views/ServiceProcess/DetailService";
import ListBlastWA from "../Views/Blast/BlastWA";
import FormBlastWA from "../Views/Blast/FormBlastWA";

function Router({ history }) {
  return (
      <HashRouter history={history}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<ProtectedLayout />} >
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/banner" element={<ListBanner />} />
            <Route path="/store" element={<ListStore />} />
            <Route path="/storePromo" element={<ListStorePromo />} />
            <Route path="/formStore" element={<FormStore />} />
            <Route path="/formStorePromo" element={<FormStorePromo />} />
            <Route path="/catalog" element={<ListCatalog />} />
            <Route path="/users" element={<ListUsers />} />
            <Route path="/formUsers" element={<FormUsers />} />
            <Route path="/serviceCenter" element={<ListServiceCenter />} />
            <Route path="/productWarranty" element={<ListProductWarranty />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/membership" element={<ListMembership />} />
            <Route path="/notification" element={<ListNotification />} />
            <Route path="/chat" element={<LiveChat />} />
            <Route path="/order" element={<ListOrder />} />
            <Route path="/orderdetail" element={<DetailOrder />} />
            <Route path="/category" element={<ListCategory />} />
            <Route path="/formCategory" element={<FormCategory />} />
            <Route path="/flashsalebanner" element={<ListFlashSaleBanner />} />
            <Route path="/flashsaleproduct" element={<ListFlashSaleProduct />} />
            <Route path="/formFlashsaleproduct" element={<FormFlashSaleProduct />} />
            <Route path="/flashsaletime" element={<ListFlashSaleTime />} />
            <Route path="/flashsale" element={<ListFlashSale />} />
            <Route path="/formFlashsale" element={<FormFlashSale />} />
            <Route path="/popularProduct" element={<ListPopularProduct />} />
            <Route path="/product" element={<ListProduct />} />
            <Route path="/level" element={<ListLevel />} />
            <Route path="/warrantyclaim" element={<ListWarrantyClaim />} />
            <Route path="/formwarrantyclaim" element={<FormWarrantyClaim />} />
            <Route path="/detailwarrantyclaim" element={<DetailWarrantyClaim />} />
            <Route path="/warrantymember" element={<ListWarrantyMember />} />
            <Route path="/whatsapp/qrcode" element={<AUWhatsapp />} />
            <Route path="/productService" element={<ProductService />} />
            <Route path="/serviceProcess" element={<ServiceProcess />} />
            <Route path="/sparepart" element={<Sparepart />} />
            <Route path="/detailservice" element={<DetailService />} />
            <Route path="/blastWA" element={<ListBlastWA />} />
            <Route path="/formBlastWA" element={<FormBlastWA />} />
            {/* <Route path="/chats" element={<LiveChat />} /> */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        </HashRouter>
  );
}


export default Router;